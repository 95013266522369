<template>
    <div class="perfect-archiving-wrap">
        <div v-if="fileList.length">
            <div class="top-box">
                <div class="title-box">
                    <!-- <img src="@/assets/img/icon-4.png" alt="" /> 我的存档 -->
                    说明：我在蚂蚁特权客户端保存的游戏存档文件
                </div>
                <div class="input-box">
                    <el-input placeholder="请输入您想要找的游戏" v-model="gameName" class="input-with-select"
                        @keydown.enter.native="handleSearch">
                        <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearch"></el-button>
                    </el-input>
                </div>
            </div>

            <div class="main-box">
                <div class="main-item" v-for="(item, index) in fileList" :key="index"
                    v-if="item.gameInfoEntity.Type == 2 || item.gameInfoEntity.Type == 3">
                    <a href="javascript:;" class="link-box" v-if="item.gameInfoEntity" @click.stop.prevent="more(item)">
                        <span class="icon">保存了{{
                            item.UserArchives && item.UserArchives.length
                        }}个存档</span>
                        <img :src="`${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_100`" class="game-img"
                            alt />
                        <div class="item-wrapper">
                            <div class="item-box">
                                <h4 v-if="item.gameInfoEntity.Type == 2 || item.gameInfoEntity.Type == 3
                                    ">
                                    {{ item.gameInfoEntity.Name }}
                                    <span class="cloud-archiving">支持存档</span>
                                </h4>
                                <h4 v-else>{{ item.gameInfoEntity.Name }}</h4>
                                <a href="javascript:;" class="btn-experience" @click.stop.prevent="more(item)">管理存档</a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <!-- 分页 -->
        <!-- <div class="pagination-box">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div> -->

        <div class="no-data" v-if="fileList.length == 0">
            <img src="@/assets/img/nocoll.png" alt="" />
            <p>暂无数据</p>
        </div>

        <el-dialog v-if="gameObj && gameObj.gameInfoEntity" :title="gameObj.gameInfoEntity.Name"
            :visible.sync="dialogTableVisible" custom-class="file-dialog" width="760px">
            <el-table :data="gridData" height="400">
                <el-table-column property="date" label="存档名称" width="200">
                    <template slot-scope="scope">
                        <p class="desc ellipsis">{{ scope.row.Describe }}</p>
                    </template>
                </el-table-column>

                <el-table-column property="date" label="存档时间" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.SaveTime }}
                    </template>
                </el-table-column>

                <el-table-column property="address" label="操作" width="380" align="center">
                    <template slot-scope="scope">
                        <el-button size="small" @click="downLoad(scope.row)">下载存档</el-button>
                        <el-button size="small" @click="toDetail(scope.row)">登录steam开始游戏</el-button>
                        <el-button size="small" @click="del(scope.row)">删除</el-button>
                        <!-- <i class="el-icon-download" @click="downLoad(scope.row)"></i>
              <i class="el-icon-delete" @click="del(scope.row)"></i> -->
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
  
<script>
import { GetUserGameArchive, DelteGameArchive } from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";

export default {
    computed: {
        ...mapGetters(["isLogin", "userInfo"]),
    },
    data() {
        return {
            pageIndex: 1,
            pageSize: 9999,
            total: 0,
            gameName: "",
            fileList: [],
            dialogTableVisible: true,
            gameObj: null,
        };
    },
    mounted() {
        // 推荐游戏
        if (this.isLogin && this.userInfo) {
            this._GetUserGameArchive();
        }
    },
    methods: {
        //获取平台、网吧用户和游戏数据
        async _GetUserGameArchive() {
            let params = {
                consumerId: this.userInfo.ConsumerId,
                gameName: this.gameName,
            };
            let res = await GetUserGameArchive(params);
            if (res.StatusCode == 200) {
                if (res.Object) {
                    this.fileList = res.Object;
                    if (this.$route.query.GameID) {
                        let resa = this.fileList.filter((item) => {
                            return item.GameID == this.$route.query.GameID;
                        });
                        this.more(resa[0]);
                    }
                }
            }
        },
        // 一页展示多少条
        handleSizeChange(val) {
            this.pageIndex = 1;
            this.pageSize = val;
            // this._GetGameList();
        },
        // 分页
        handleCurrentChange(val) {
            this.pageIndex = val;
            // this._GetGameList();
            _czc.push(["_trackEvent", "免费玩页面", "分页点击", "统计量"]);
        },
        more(item) {
            this.dialogTableVisible = true;
            this.gameObj = item;
            this.gridData = item.UserArchives;

            // if (this.gridData.length) {
            //   this.gridData.map((i) => {
            //     let arr = [];
            //     if (i.PerfectArchiveImgDtos.length) {
            //       i.PerfectArchiveImgDtos.map((o) => {
            //         arr.push(o.ImgUrl);
            //       });
            //     }
            //     i.previewSrcList = arr;
            //   });
            // }
        },
        handleSearch() {
            if (this.isLogin && this.userInfo) {
                this.pageIndex = 1;
                this._GetUserGameArchive();
            } else {
                eventBus.$emit("getQrCode");
            }
        },
        // 下载
        downLoad(row) {
            if (this.isLogin && this.userInfo) {
                this.$confirm("请先关闭游戏和备份当前电脑游戏存档再进行下载！", {
                    confirmButtonText: "下载并替换存档",
                    distinguishCancelAndClose: true,
                })
                    .then(() => {
                        cMethods.Form_jsDownSaveForTQ(
                            row.GameInfoId,
                            row.GameArchiveInfoZipURL
                        );
                    })
                    .catch((action) => { });
            } else {
                eventBus.$emit("getQrCode");
            }
        },
        // 下载到详情
        toDetail(row) {
            this.$router.push(`/gameDetail/${this.gameObj.ProductID}`);
        },
        // 删除
        del(row) {
            this.$confirm("是否确定要删除该存档信息吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(async () => {
                    let params = {
                        archiveId: row.Id,
                    };
                    let res = await DelteGameArchive(params);
                    if (res.StatusCode == 200) {
                        this.gridData.splice(
                            this.gridData.findIndex((item) => item.Id == row.Id),
                            1
                        );

                        this.$message({
                            message: "删除存档成功",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.Message,
                            type: "error",
                        });
                    }
                })
                .catch((action) => {
                    // if (action == "cancel") {
                    //   cMethods.JsDownFileAndOpenDir(row.GameArchiveInfoZipURL);
                    // }
                });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.perfect-archiving-wrap {

    // padding: 20px;
    // height: 676px;
    .top-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-box {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #ff3636;
        }

        .input-box {
            box-sizing: border-box;

            ::v-deep .el-input-group__append {
                border: none !important;
            }

            .input-with-select {
                .el-input__inner {
                    border: 1px solid #e6e6e6;
                    background-color: #fff !important;
                }

                ::v-deep .el-input__inner:focus {
                    border-color: #ff3636 !important;
                }

                .el-button--primary {
                    border-radius: 0px 4px 4px 0;
                    color: #fff;
                    background-color: #ff3636 !important;
                    border-color: #ff3636 !important;
                    padding: 13px 20px;
                }
            }
        }
    }

    .main-box {
        display: flex;
        flex-wrap: wrap;
        // align-content: flex-start;
        // justify-content: space-between;
        box-sizing: border-box;
        overflow: auto;

        .main-item {
            width: 192px;
            height: 170px;
            margin-right: 18px;
            margin-top: 12px;
            position: relative;
            box-sizing: border-box;
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            @include item_bg_col();

            .icon-img {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 101;
            }

            a {
                width: 192px;
                height: 170px;
                display: block;
                position: relative;

                .icon {
                    padding: 5px 10px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #fff;
                    font-size: 12px;
                    background: linear-gradient(90deg, #ff3636 0%, #ff765a 100%);
                }

                .game-img {
                    width: 100%;
                    height: 100px;
                }

                .item-wrapper {
                    width: 100%;

                    .item-box {
                        display: flex;
                        flex-direction: column;
                        padding: 10px;

                        h4 {
                            text-align: center;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            line-height: 20px;
                            @include font_col3();

                            .cloud-archiving {
                                padding: 5px;
                                margin-left: 10px;
                                background: $linear-col;
                                border-radius: 4px;
                                color: $white-col;
                                font-size: 12px;
                            }
                        }

                        .btn-experience {
                            width: 100%;
                            height: 26px;
                            line-height: 26px;
                            text-align: center;
                            margin: 5px auto;
                            font-size: 16px;
                            color: #999;
                            border-radius: 4px;
                            font-size: 14px;
                            border: 1px solid #ccc;
                        }
                    }
                }
            }
        }

        .main-item:nth-child(5n) {
            margin-right: 0;
        }

        .main-item:hover {
            transform: translateY(-5px);
            transition: 0.2s ease;
            box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

            h4 {
                color: $red-col;
            }

            .text-hidden {
                display: none;
            }

            .btn-experience {
                border: none !important;
                color: $white-col !important;
                background: $red-col;
            }
        }
    }

    .pagination-box {
        margin-top: 34px;
        text-align: center;
    }

    .no-data {
        min-height: 466px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin-top: 10px;
            font-size: 16px;
            @include font_col3();
        }
    }
}
</style>
  
  
<style lang="scss">
.file-dialog {
    .el-dialog__body {
        padding: 10px !important;

        td.el-table__cell,
        th.el-table__cell.is-leaf {
            border-bottom: none !important;
        }

        .el-table .el-table__cell {
            padding: 5px 0 !important;
        }

        .desc {
            width: 200px;
        }

        .el-button {
            border: none;
            background: linear-gradient(0deg, #ff4f4f 0%, #ff7878 100%);
            color: #fff;
        }

        i {
            font-size: 24px;
        }
    }
}
</style>